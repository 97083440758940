<div [class.noscroll]="loading || cropping">
  <div *ngFor="let font of konfig.selectedFonts" style="height: 0; overflow: hidden" [style.font-family]="font">
    {{font}} <!-- ich erzwinge das laden der eigentlichen Fonts damit es für den Canvas schon da ist -->
  </div>
  <mat-horizontal-stepper #stepper [linear]="true" style="padding:8px" [(selectedIndex)]="selectedIndex"
                          (selectionChange)="selectionChanged($event, formGroup1, formGroup2, formGroup3)">
    <mat-step [stepControl]="formGroup1" [optional]="true">

      <ng-template matStepLabel>
        Konfiguration
      </ng-template>
      <h1>Konfiguration laden</h1>

      <form #formGroup1="ngForm"
            style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; gap: 16px">

        <mat-form-field style="flex-grow: 1">
          <mat-label>Konfigurationsdatei wählen</mat-label>
          <ngx-mat-file-input name="konfig" [multiple]="false" [(ngModel)]="konfigFile" [accept]="'application/json'"
                              (ngModelChange)="loadConfig()" required></ngx-mat-file-input>
          <mat-hint>Wenden sie sich bitte an <a
            href="mailto:susanne.peters@ad-alliance.de?subject=Cookie Cutter/Schnipseltool Konfigurationsdatei&body=Hallo%20Susanne,%0D%0Akannst du mir bitte die aktuelle Konfigurationsdatei zusenden?%0D%0ADanke!">Susanne
            Peters</a> (Susanne.Peters@ad-alliance.de) um eine aktuelle Konfigurationsdatei zu erhalten
          </mat-hint>
        </mat-form-field>
        <div>
          <mat-slide-toggle color="primary" #k ngModel [ngModelOptions]="{standalone: true}">Expertenmodus
          </mat-slide-toggle>
        </div>
      </form>
      <ng-container *ngIf="k.checked">

        <app-konfiguration-editor></app-konfiguration-editor>
      </ng-container>
      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: right">

        <button class="next" mat-raised-button color="primary"
                matStepperNext>
          <span style="text-transform: uppercase;">Weiter</span></button>
      </div>


    </mat-step>
    <mat-step [stepControl]="formGroup2">
      <ng-template matStepLabel>
        Webseite wählen
      </ng-template>
      <div style="margin-left: auto; margin-right: auto">

        <div>
          <form class="w-100" #formGroup2="ngForm"
                style="padding: 20px;">
            <mat-radio-group [(ngModel)]="selectedSite" [disabled]="!konfig" aria-label="Wählen sie eine Webseite"
                             name="website"
                             required>
              <mat-radio-button name="website" [value]="ws"
                                *ngFor="let ws of konfig?.websites">{{ ws.name }}</mat-radio-button>

            </mat-radio-group>

          </form>
          <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: right">
            <button mat-raised-button color="primary"
                    matStepperNext>
              <span style="text-transform: uppercase;">Weiter</span></button>
          </div>
        </div>
      </div>


    </mat-step>
    <mat-step [stepControl]="formGroup3">
      <ng-template matStepLabel>
        Bilder wählen
      </ng-template>
      <div style="margin-left: auto; margin-right: auto">
        <form #formGroup3="ngForm">
          <div *ngFor="let f of this.kategorieNachFormat | keyvalue:katSort">

            <h2>{{ f.key }} wählen</h2>
            <file-upload name="{{ f.key }}" [(ngModel)]="f.value.files" [disabled]="!konfig">
              <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                <file-upload-drop-zone>
                  <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
                    Ziehen Sie Dateien per <b>Drag & Drop</b><br> herein oder klicken Sie hier
                  </ng-container>
                  <ng-template #isNotDragDropAvailable>
                    <b>Klicken Sie hier</b> um<br> eine Datei auszuwählen
                  </ng-template>
                </file-upload-drop-zone>
              </ng-template>

              <ng-template let-i="index" let-file="file" let-control="control" #item>
                <file-upload-list-item [index]="i" [file]="file" [control]="control">Entfernen</file-upload-list-item>
              </ng-template>
            </file-upload>

          </div>
          <h2>Kunde / Artikel</h2>
          <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; gap: 16px">
            <mat-form-field style="flex-grow:1">
              <mat-label>Kunde</mat-label>
              <input matInput name="kunde" [disabled]="!konfig" [(ngModel)]="kunde" pattern="[a-z0-9][a-z0-9\s]*"
                     required
                     #kundeField="ngModel"
              />
              <mat-icon matSuffix>edit</mat-icon>
              <mat-hint>Bitte tragen sie hier den Kunden gemäß Namenskonvention ein (nur Kleinschreibung)</mat-hint>

              <mat-error *ngIf="kundeField.errors && kundeField.errors.pattern">
                Der Kunde muss mit einem kleinen Buchstaben beginnen und sonst ausschließlich kleine Buchstaben oder
                Zahlen enthalten
              </mat-error>
              <mat-error *ngIf="kundeField.errors &&  kundeField.errors.required">
                Der Kunde ist ein Pflichtfeld
              </mat-error>

            </mat-form-field>
            <mat-form-field style="flex-grow:1">
              <mat-label>Artikelname</mat-label>
              <input matInput name="artikel" [disabled]="!konfig" [(ngModel)]="artikel" pattern="[a-z0-9][a-z0-9\s]*"
                     required
                     #artikelField="ngModel"
              />
              <mat-icon matSuffix>edit</mat-icon>
              <mat-hint>Bitte tragen sie hier den Artikelnamen gemäß Namenskonvention ein (nur Kleinschreibung)
              </mat-hint>

              <mat-error *ngIf="artikelField.errors && artikelField.errors.pattern">
                Der Artikelname muss mit einem kleinen Buchstaben beginnen und sonst ausschließlich kleine Buchstaben,
                Leerzeichen oder
                Zahlen enthalten
              </mat-error>
              <mat-error *ngIf="artikelField.errors && artikelField.errors.required">
                Der Artikelname ist ein Pflichtfeld
              </mat-error>

            </mat-form-field>
          </div>
        </form>
        <div *ngIf="alleBilder >= 0 && geladeneBilder / alleBilder < 1" class="mat-warn">Bitte warten sie bis alle
          Bilder geladen sind.
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: right">
          <button class="next" mat-raised-button color="primary"
                  matStepperNext>
            <span style="text-transform: uppercase;">Weiter</span></button>
        </div>
      </div>

    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        Auschnitte wählen
      </ng-template>
      <div style="display: flex; flex-direction: column; justify-content: center">
        <div *ngFor="let kat of bilderMitPrefix | keyvalue:katSort">
          <ng-container *ngIf="kat.value && kat.value.length > 0 ">
            <h1>{{ kat.key }}</h1>
            <ng-container *ngFor="let bl of kat.value; let i = index">
              <div style="margin-top: 20px">
                <div></div>
                <mat-form-field style="font-size: 24px; width: 100%">
                  <mat-label>Aufzählungszeichen ({{ i + 1 }}. Bild der Gruppe {{kat.key}})</mat-label>
                  <input matInput name="{{bl.aufzaehlung}}-nummer" placeholder="Name des Bildes"
                         [(ngModel)]="bl.aufzaehlung"
                         pattern="[a-z0-9]+" required #picname="ngModel">
                  <mat-icon matSuffix>edit</mat-icon>
                  <mat-error *ngIf="picname.errors && picname.errors.pattern">
                    Das Aufzählungszeichen sollte entweder eine Zahl oder ein Buchstabe sein.
                  </mat-error>
                  <mat-error *ngIf="picname.errors &&  picname.errors.required">
                    Das Aufzählungszeichen ist ein Pflichtfeld
                  </mat-error>
                  <mat-hint>ursprünglich: {{ bl.file.name }}
                  </mat-hint>
                </mat-form-field>

                <div></div>
              </div>

              <ng-container *ngFor="let f of bl.crops">
                <mat-card class="cropper-card" [hidden]="loading">
                  <mat-card-header>
                    <h2>{{kunde}}_{{artikel}}
                      _{{ f.format.kategorie.partikel}}{{bl.aufzaehlung}}_{{f.format.name}}.jpg</h2>

                  </mat-card-header>
                  <mat-card-content>
                    <image-cropper #me id="{{ bl.aufzaehlung }}{{f.format.name}}"
                                   [maintainAspectRatio]="true"
                                   [aspectRatio]="f.format.breite / f.format.hoehe"
                                   [resizeToWidth]="f.format.breite"
                                   [resizeToHeight]="f.format.hoehe"
                                   (imageLoaded)="log('imageLoaded');"
                                   [autoCrop]="false"
                                   format="jpeg"
                                   [imageFileChanged]="bl.file"
                                   (cropperReady)="log('cropperReady'); selectCropArea(f, me); cropperReady(f); log('cropped')"
                                   (imageCropped)="imageCropped($event, f, me);"
                    >
                    </image-cropper>
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </ng-container>
            <div style="padding-top: 16px; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: right">
              <button mat-raised-button color="primary"
                      matStepperNext>
                <span style="text-transform: uppercase;">Weiter</span></button>
            </div>
          </ng-container>
        </div>

      </div>
    </mat-step>
    <mat-step>
      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: right">
        <button mat-raised-button color="primary"
                matStepperNext>
          <span style="text-transform: uppercase;">Weiter</span></button>
      </div>
      <ng-template matStepLabel>
        Overlays
      </ng-template>
      <div style="display: flex; flex-direction: column; flex-wrap: wrap; justify-content: right">
        <div *ngFor="let kat of bilderMitPrefix  | keyvalue:katSort">
          <ng-container *ngIf="kat.value && kat.value.length > 0 ">
            <h1>{{ kat.key }}</h1>
            <ng-container *ngFor="let bl of kat.value">
              <div style="margin-top: 20px">
                <div>
                  <ng-container *ngFor="let f of bl.crops">
                    <mat-card *ngIf="f.cropResult" class="result-card" style="margin-top: 20px">
                      <mat-card-header>
                        <mat-card-title>{{kunde}}_{{artikel}}_{{ f.format.kategorie.partikel}}{{bl.aufzaehlung}}
                          _{{f.format.name}}.jpg
                        </mat-card-title>

                      </mat-card-header>
                      <mat-card-content>
                        <app-image-overlayer [cropResult]="f.cropResult" [availableFonts]="konfig.selectedFonts"
                                             (overlayResult)="f.overlayResult = $event"></app-image-overlayer>
                      </mat-card-content>
                    </mat-card>

                  </ng-container>
                </div>

              </div>

            </ng-container>
          </ng-container>
        </div>

      </div>
      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: right">
        <button mat-raised-button color="primary"
                matStepperNext>
          <span style="text-transform: uppercase;">Weiter</span></button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>
        Vorschau
      </ng-template>

      <div style="display: flex; flex-direction: column; flex-wrap: wrap; justify-content: right">
        <div *ngFor="let kat of bilderMitPrefix  | keyvalue:katSort">
          <h1>{{ kat.key }}</h1>
          <div *ngFor="let bl of kat.value">
            <ng-container *ngIf="kat.value && kat.value.length > 0 ">
              <div style="margin-top: 20px">
                <div>
                  <ng-container *ngFor="let f of bl.crops">
                    <mat-card *ngIf="f.cropResult" class="result-card" style="margin-top: 20px">
                      <mat-card-header>
                        <mat-card-title>{{kunde}}_{{artikel}}_{{ f.format.kategorie.partikel}}{{bl.aufzaehlung}}
                          _{{f.format.name}}.jpg
                        </mat-card-title>
                        <mat-card-subtitle>{{ f.compressResult?.size | bytes }}
                          <mat-error *ngIf="f.warnung">{{ f.warnung }}</mat-error>
                        </mat-card-subtitle>
                      </mat-card-header>
                      <mat-card-content>
                        <app-image-compressor
                          *ngIf="selectedIndex === 5"
                          [overlayResult]="f.overlayResult"
                          (compressResult)="log('xxx + ' + $event?.size); f.compressResultObs.next($event); f.compressResultObs.complete()"
                          [format]="f.format"></app-image-compressor>
                        <button style="position: absolute; bottom: 10px; right: 10px" mat-mini-fab
                                (click)="edit(bl.aufzaehlung + f.format.name)">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </mat-card-content>

                    </mat-card>

                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div style="margin-top: 20px" *ngIf="downloadUrl; else progress">
          <div></div>


          <span> Laden sie die Zuschnitte hier herunter: <a
            *ngIf="stepper.selectedIndex == 5 && downloadUrl else progress" [href]="downloadUrl"
            [download]="filename">

          {{filename}}.</a><br/>Hinweis: Benutzen Sie den rechten Mausknopf und wählen sie 'Speichern Unter' um den Ablageort zu wählen.</span>
        </div>
        <ng-template #progress>
          <mat-progress-bar  mode="determinate" [value]="100 * cropsDone / crops.length"></mat-progress-bar>
        </ng-template>
      </div>

    </mat-step>
  </mat-horizontal-stepper>
  <div>{{ version }}</div>
</div>

<app-version-upgrader></app-version-upgrader>
<div class="backdrop" *ngIf="loading">
  <mat-progress-spinner color="primary" [value]="geladeneBilder / alleBilder * 100"></mat-progress-spinner>
</div>
<div class="backdrop" *ngIf="cropping">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
