import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {distinctUntilChanged, interval, map} from 'rxjs';

const SCALER_SIZE = 5;

@Component({
  selector: 'app-image-overlayer',
  templateUrl: './image-overlayer.component.html',
  styleUrls: ['./image-overlayer.component.scss']
})
export class ImageOverlayerComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  public dx = 0;
  public dy = 0;
  public scale = 0.8;

  width: number;
  height: number;

  initMoveOnClick = false;
  initResizeOnClick = false;
  moving = false;
  resizing = false;

  selectedImage: OffscreenCanvas = null;

  @Input()
  cropResult: Blob;

  @Input()
  availableFonts: string[];

  @Output()
  overlayResult = new EventEmitter<Blob>();
  verbrauchImage: OffscreenCanvas;
  logoImage: OffscreenCanvas;


  constructor(private zone: NgZone) {
    interval(50).pipe(
      map(() => [this.dx, this.dy, this.scale]),
      distinctUntilChanged((old, nu) => old[0] === nu[0] && old[1] === nu[1] && old[2] === nu[2])
    ).subscribe(() => {
      if (this.selectedImage) {
        this.sanitizeDxDy();
        this.paintBG();
      }
    });
  }

  public ctx: CanvasRenderingContext2D;
  orientation = 'row';


  nameAttr(a, b) {
    return a && b ? a.name === b.name : a === b;
  }

  ngOnInit(): void {
    this.overlayResult.next(this.cropResult);
  }

  ngAfterViewInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.paintFG();
    this.scaleAndPosition(1, 0, 0);
  }

  updateOutputs() {
    this.canvas.nativeElement.toBlob(
      (result: Blob | null) => this.zone.run(() => {
        this.overlayResult.next(result);
      }),
      'image/jpeg',
      1
    );
  }

  paintBG(): void {
    const image = new Image();
    if (this.cropResult && this.ctx) {
      image.onload = () => {

        this.ctx.canvas.height = this.height = image.height;
        this.ctx.canvas.width = this.width = image.width;
        this.ctx.drawImage(image, 0, 0);
        this.paintFG();
        URL.revokeObjectURL(image.src);
      };
      image.setAttribute('src', URL.createObjectURL(this.cropResult));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.paintBG();
  }


  private paintFG() {
    if (this.selectedImage) {
      this.ctx.drawImage(
        this.selectedImage,
        this.dx, this.dy,
        this.selectedImage.width * this.scale, this.selectedImage.height * this.scale);
      this.updateOutputs();
    } else {
      this.overlayResult.next(this.cropResult);
    }
  }

  sanitizeDxDy() {

    if (this.dx + this.selectedImage.width * this.scale > this.ctx.canvas.width) {
      this.dx = this.ctx.canvas.width - this.selectedImage.width * this.scale;
    }
    if (this.dx < 0) {
      this.dx = 0;
    }
    if (this.dy + this.selectedImage.height * this.scale > this.ctx.canvas.height) {
      this.dy = this.ctx.canvas.height - this.selectedImage.height * this.scale;
    }
    if (this.dy < 0) {
      this.dy = 0;
    }
    this.dy = Math.round(this.dy);
    this.dx = Math.round(this.dx);
  }

  handleMouseMove($event: MouseEvent) {
    if (this.selectedImage) {
      if (this.moving) {
        this.dx = this.dx + $event.movementX;
        this.dy = this.dy + $event.movementY;
        this.sanitizeDxDy();
      } else if (this.resizing) {
        this.scale = ($event.offsetX - this.dx) / this.ctx.canvas.width * (this.ctx.canvas.width / this.selectedImage.width);
      } else {
        this.ctx.beginPath();
        this.ctx.moveTo(this.dx, this.dy);
        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale, this.dy);

        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale,
          this.dy + this.selectedImage.height * this.scale - SCALER_SIZE);

        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale - SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale - SCALER_SIZE);

        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale - SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale);

        this.ctx.lineTo(this.dx, this.dy + this.selectedImage.height * this.scale);
        this.ctx.lineTo(this.dx, this.dy);
        this.ctx.lineWidth = 1;
        this.ctx.closePath();
        this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.35)';
        this.ctx.setLineDash([2, 2]);
        this.ctx.stroke();

        this.ctx.strokeStyle = 'rgba(255, 255, 255, 0.35)';
        this.ctx.lineDashOffset = 1;
        this.ctx.setLineDash([2, 2]);
        this.ctx.stroke();

        if (this.ctx.isPointInPath($event.offsetX, $event.offsetY)) {
          document.body.style.cursor = 'move';
          this.initMoveOnClick = true;
        } else {
          this.initMoveOnClick = false;
        }


        this.ctx.beginPath();
        this.ctx.moveTo(this.dx + this.selectedImage.width * this.scale - SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale - SCALER_SIZE);
        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale + SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale - SCALER_SIZE);
        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale + SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale + SCALER_SIZE);
        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale - SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale + SCALER_SIZE);
        this.ctx.lineTo(this.dx + this.selectedImage.width * this.scale - SCALER_SIZE,
          this.dy + this.selectedImage.height * this.scale - SCALER_SIZE);
        this.ctx.setLineDash([]);
        this.ctx.strokeStyle = 'rgba(255, 255, 255, 0.35)';
        this.ctx.lineWidth = 1;
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fillStyle = 'rgba(0,0,0,0.35)';
        this.ctx.fill();


        if (this.ctx.isPointInPath($event.offsetX, $event.offsetY)) {
          document.body.style.cursor = 'nwse-resize';
          this.initResizeOnClick = true;
        } else {
          this.initResizeOnClick = false;
        }

        if (!this.initMoveOnClick && !this.initResizeOnClick) {
          document.body.style.cursor = 'auto';

        }
      }
    }
  }

  mouseDown() {
    if (this.initMoveOnClick) {
      this.moving = true;
    }
    if (this.initResizeOnClick) {
      this.resizing = true;
    }
  }

  mouseUp() {
    this.moving = false;
    this.resizing = false;
  }

  scaleAndPosition(scalePercent: number, xPercent: number, yPercent: number) {
    if (this.selectedImage !== null) {
      if (scalePercent !== null) {
        const ratio = this.ctx.canvas.width / this.selectedImage.width;
        this.scale = scalePercent / 100 * ratio;
      }
      if (xPercent !== null) {
        this.dx = xPercent / 100 * this.ctx.canvas.width;
        this.sanitizeDxDy();
      }
      if (yPercent !== null) {
        this.dy = this.ctx.canvas.height * (100 - yPercent) / 100 - this.selectedImage.height * this.scale;
        this.sanitizeDxDy();
      }
    }
    this.paintBG();
  }

  tabSelected(index) {
    if (index === 0) {
      this.selectedImage = null;
      this.paintBG();
    } else if (index === 1) {
      this.selectedImage = this.verbrauchImage;
      this.scaleAndPosition(0.9, 5, 4);
    } else if (index === 2) {
      this.selectedImage = this.logoImage;
      if (this.selectedImage && this.selectedImage.width > this.ctx.canvas.width) {
        this.widthInPixel = 100;
      }
    }
  }

  set widthInPixel(x: number) {
    this.scale = x / this.selectedImage.width;
  }

  get widthInPixel() {
    if (this.selectedImage) {
      return Math.round(this.selectedImage.width * this.scale);
    }
  }

  set widthInPercent(x: number) {
    this.scale = ((x / 100) * this.ctx.canvas.width) / this.selectedImage.width;
  }

  get widthInPercent() {
    if (this.selectedImage) {
      return Math.round(100 * this.selectedImage.width * this.scale / this.ctx.canvas.width);
    }
  }

  set fontSize(x: number) {
    this.scale = x / 50;
  }

  get fontSize() {
    if (this.selectedImage) {
      return Math.round(this.scale * 50 * 4) / 4;
    }
  }

  set d100x(x: number) {
    this.dx = this.ctx.canvas.width - this.selectedImage.width * this.scale - x;
    this.sanitizeDxDy();
  }

  get d100x() {
    if (this.selectedImage) {
      return this.ctx.canvas.width - this.selectedImage.width * this.scale - this.dx;
    }
  }
  set d100y(y: number) {
    this.dy = this.ctx.canvas.height - this.selectedImage.height * this.scale - y;
    this.sanitizeDxDy();
  }

  get d100y() {
    if (this.selectedImage) {
      return this.ctx.canvas.height - this.selectedImage.height * this.scale - this.dy;
    }
  }

}
