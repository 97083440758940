import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MaterialModule} from './material-module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {KonfigurationEditorComponent} from './konfiguration-editor/konfiguration-editor.component';
import {VersionUpgraderComponent} from './version-upgrader/version-upgrader.component';
import {ImageCropperModule} from './ngx-image-cropper/image-cropper.module';
import {BytesPipe} from './bytes.pipe';
import {SelectPipe} from './konfiguration-editor/select.pipe';
import * as Sentry from '@sentry/browser';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {WebsiteEditorComponent} from './konfiguration-editor/website-editor/website-editor.component';
import {ImageOverlayerComponent} from './image-overlayer/image-overlayer.component';
import {ImageCompressorComponent} from './image-compressor/image-compressor.component';
import {VerbrauchsTextGeneratorComponent} from './verbrauchs-text-generator/verbrauchs-text-generator.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {OverlayModule} from '@angular/cdk/overlay';
import {ColorPickerModule} from 'ngx-color-picker';
import {HttpClientModule} from '@angular/common/http';
import {ImageUploaderComponent} from './image-uploader/image-uploader.component';

/*Sentry.init({
  dsn: 'https://15d0fd5ba13a426882cbf80f9ccfc59c@sentry.io/1812711'
});*/

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    Sentry.showReportDialog({eventId});
  }
}

@NgModule({
  declarations: [
    AppComponent,
    KonfigurationEditorComponent,
    VersionUpgraderComponent,
    BytesPipe,
    SelectPipe,
    WebsiteEditorComponent,
    ImageOverlayerComponent,
    ImageCompressorComponent,
    VerbrauchsTextGeneratorComponent,
    ImageUploaderComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    OverlayModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    FileUploadModule,
    NgxMatFileInputModule,
    TextFieldModule,
    ImageCropperModule,
    ColorPickerModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {float: 'auto'}},
    // {provide: ErrorHandler, useClass: SentryErrorHandler}
  ]
})
export class AppModule {
}
