import {Format} from './konfiguration.service';
import {SafeResourceUrl} from '@angular/platform-browser';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

export class Crop {
  format: Format;
  cropResult: Blob;
  overlayResult: Blob;
  compressResultObs = new ReplaySubject<Blob>(1);
  warnung?: string;
  loaded?: boolean;

  constructor(format) {
    this.format = format;

  }
}
