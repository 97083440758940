import {Component, EventEmitter, Output} from '@angular/core';
import {$e} from 'codelyzer/angular/styles/chars';
import {FileOrArrayFile} from '@angular-material-components/file-input';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {

  @Output()
  image = new EventEmitter<OffscreenCanvas>();

  canvas = new OffscreenCanvas(0, 0);
  private ctx: OffscreenCanvasRenderingContext2D = this.canvas.getContext('2d');
  file: FileOrArrayFile;

  loadFile(file: File) {
    console.log(file);
    const img = new Image();
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        img.addEventListener('load', () => {
          this.ctx.canvas.height = img.height;
          this.ctx.canvas.width = img.width;
          this.ctx.drawImage(img, 0, 0);
          this.image.next(this.canvas);
        });
        img.src = reader.result as string;
      });
      reader.readAsDataURL(file);
    }
  }
}
