import {Component, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-version-upgrader',
  template: '',
})
export class VersionUpgraderComponent implements OnInit {

  constructor(private swUpdate: SwUpdate, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.swUpdate.available.subscribe(event => {
      const snackBarRef = this.snackBar.open('Es gibt eine neue Version.', 'Aktualisieren');
      snackBarRef.onAction().subscribe(() => {
        window.location.reload();
      });

    });

  }

}
