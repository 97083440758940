import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {timer} from 'rxjs';

@Component({
  selector: 'app-verbrauchs-text-generator',
  templateUrl: './verbrauchs-text-generator.component.html',
  styleUrls: ['./verbrauchs-text-generator.component.scss']
})
export class VerbrauchsTextGeneratorComponent implements OnInit, AfterViewInit, OnChanges {
  canvas = new OffscreenCanvas(0, 0);

  @Output()
  image = new EventEmitter<OffscreenCanvas>();

  @Input()
  availableFonts: string[];

  selectedFont: string;


  private ctx: OffscreenCanvasRenderingContext2D;

  text = '';
  padding = 10;
  align: CanvasTextAlign = 'center';

  fondColor = '#ffffff80';
  fontColor = '#000000ff';

  ngOnChanges(changes: SimpleChanges) {
    if (!this.selectedFont) {
      this.selectedFont = this.availableFonts[0];
      if (this.ctx) {
        this.updateCanvas();
      }
    }
  }

  constructor(public viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
  }

  tiefGestellte2(pos) {
    this.text = this.text.slice(0, pos) + 'CO₂' + this.text.slice(pos);

  }

  updateCanvas() {

    console.log('updateCanvas');
    console.log(`50px ${this.selectedFont}`);
    if (this.text === '') {
      this.image.next(null);
      return;
    }
    this.ctx.font = `50px ${this.selectedFont}`;
    this.ctx.textAlign = this.align;
    let maxWidth = 0;
    for (const line of this.text.split('\n')) {
      const w = this.ctx.measureText(line).width;
      maxWidth = maxWidth > w ? maxWidth : w;
    }
    const numberOfLines = this.text.split('\n').length;
    const top = this.ctx.measureText(this.text).fontBoundingBoxAscent;
    const bottom = this.ctx.measureText(this.text).fontBoundingBoxDescent;
    const lineHeight = top + bottom;
    const lineSpacing = 1.2;

    this.ctx.canvas.width = maxWidth + 2 * this.padding;
    this.ctx.canvas.height = lineHeight + lineHeight * lineSpacing * (numberOfLines - 1) + 2 * this.padding;
    this.ctx.fillStyle = this.fondColor;
    this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    this.ctx.fillStyle = this.fontColor;
    this.ctx.font = `50px ${this.selectedFont}`;
    this.ctx.textAlign = this.align;

    let x = 0;
    if (this.align === 'left') {
      x = this.padding;
    } else if (this.align === 'right') {
      x = maxWidth + this.padding;
    } else if (this.align === 'center') {
      x = maxWidth / 2 + this.padding;
    }
    let y = this.padding + top;

    for (const line of this.text.split('\n')) {
      this.ctx.fillText(line, x, y);
      y += lineHeight * lineSpacing;
    }
    this.emitPic();
  }

  ngAfterViewInit(): void {
    this.ctx = this.canvas.getContext('2d');
    this.updateCanvas();
  }

  emitPic() {
    const canvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
    canvas.getContext('2d').drawImage(this.canvas, 0, 0);
    this.image.next(canvas);
    console.log('added image');
  }

}
