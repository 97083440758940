import {Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Crop} from '../cropResult';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable, of, timer} from 'rxjs';
import {catchError, retryWhen, tap} from 'rxjs/operators';
import {Format} from '../konfiguration.service';

@Component({
  selector: 'app-image-compressor',
  templateUrl: './image-compressor.component.html',
  styleUrls: ['./image-compressor.component.scss']
})
export class ImageCompressorComponent implements OnInit, OnChanges {

  constructor(private zone: NgZone, private domSanitizer: DomSanitizer) {
  }

  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;
  img = new Image();

  @Input()
  overlayResult: Blob;

  @Input()
  format: Format;

  @Output()
  compressResult = new EventEmitter<Blob>();

  private ctx: CanvasRenderingContext2D;

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges');
    if (changes.overlayResult.currentValue !== changes.overlayResult.previousValue) {
      this.paintBG();
    }

  }

  ngOnInit(): void {
    console.log('ImageCompressorComponent INIT');
    this.ctx = this.canvas.nativeElement.getContext('2d');
  }

  paintBG(): void {
    const image =  new Image();
    if (this.overlayResult) {
      image.onload = () => {
        console.log('onload');
        this.ctx.canvas.height = image.height;
        this.ctx.canvas.width = image.width;
        this.ctx.drawImage(image, 0, 0);
        this.compress();
      };

      if (image.src !== '') {
        URL.revokeObjectURL(image.src);
      }
      image.setAttribute('src',  URL.createObjectURL(this.overlayResult));

    }
  }

  compress() {
    let quality = 1;
    new Observable<Blob>((observer) => {
      this.canvas.nativeElement.toBlob(
        (result: Blob | null) => this.zone.run(() => {
          this.ctx.drawImage(this.img, 0, 0);
          if (result.size > this.format.max * 1000) {
            if (quality < 0.1) {
              observer.error(result);
            } else {
              quality -= 0.05;
              observer.error('size');
            }
          } else {
            observer.next(result);
            observer.complete();
          }
        }),
        'image/jpeg',
        quality
      );
    }).pipe(
      retryWhen(errors => {
        return errors.pipe(
          tap(errs => {
            if (errs === 'size') {
              return;
            } else {
              alert('Das Bild ' + this.format.name + ' kann nicht auf die gewünschte Größe ( ' + this.format.max + 'kb ) komprimiert werden');
              throw errs;
            }
          }));
      }),
      catchError((err, caught) => {
        if (err instanceof Blob) {
          return of(err);
        } else {
          return caught;
        }
      })
    ).subscribe(blob => {
      console.log('Compress Result gesetzt ' + blob.size);
      this.compressResult.next(blob);
      const image = new Image();
      image.onload = () => {
        console.log('onload');
        this.ctx.canvas.height = image.height;
        this.ctx.canvas.width = image.width;
        this.ctx.drawImage(image, 0, 0);
      };

      if (image.src !== '') {
        URL.revokeObjectURL(image.src);
      }
      image.setAttribute('src', URL.createObjectURL(blob));

    }, error => {
        console.log(error);
    });
  }


}
