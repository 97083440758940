<div style="display: flex; flex-wrap: wrap; gap: 8px; flex-direction: row; ">
  <div style="flex-grow: 1; position: relative; height: 100%">
    <mat-form-field color="primary" style="width: 100%">
      <mat-label>Verbrauchsinformation bearbeiten</mat-label>
      <textarea #textAreaElement [(ngModel)]="text" (change)="updateCanvas()" matInput
                placeholder="Verbrauchsinformation einfügen"
                cdkTextareaAutosize
                cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
    </mat-form-field>
    <button style="position: absolute; right: 16px ; bottom: 32px" mat-fab color="primary"
            (click)="tiefGestellte2(textAreaElement.selectionStart); textAreaElement.focus()">CO₂
    </button>
  </div>

  <div style="display: flex; flex-wrap: wrap; flex-direction: column; ">
    <div style="display: flex; flex-direction: row; gap: 8px; " >

      <mat-form-field style="flex-basis: 100px; flex-grow: 1" (cpInputChange)="updateCanvas()" [cpOutputFormat]="'hex'" [cpAlphaChannel]="'forced'"   [(colorPicker)]="fondColor" (colorPickerChange)="updateCanvas()" >
        <mat-label>Hintergrund</mat-label>
        <input matInput
               [(ngModel)]="fondColor"
               (change)="updateCanvas()"
        >

      </mat-form-field>
      <mat-form-field style="flex-basis: 100px; flex-grow: 1" (cpInputChange)="updateCanvas()" [cpOutputFormat]="'hex'" [cpAlphaChannel]="'forced'" [(colorPicker)]="fontColor" (colorPickerChange)="updateCanvas()">
        <mat-label>Text</mat-label>

        <input matInput
               [(ngModel)]="fontColor"
               (change)="updateCanvas()"
        >

      </mat-form-field>
    </div>
    <div style="display: flex; flex-wrap: wrap; flex-direction: row; gap: 8px ">
      <div style="flex-basis: 100px; flex-grow: 1">
        <mat-button-toggle-group [(ngModel)]="align" #group="matButtonToggleGroup">
          <mat-button-toggle value="left" aria-label="Text align left" (change)="updateCanvas()">
            <mat-icon>format_align_left</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="center" aria-label="Text align center" (change)="updateCanvas()">
            <mat-icon>format_align_center</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="right" aria-label="Text align right" (change)="updateCanvas()">
            <mat-icon>format_align_right</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div style="flex-basis: 100px; flex-grow: 1">
        <div style="display: flex; flex-direction: row; ">
          <mat-form-field style="width: 100%">
            <mat-label>Font auswählen</mat-label>
            <mat-select matInput [(ngModel)]="selectedFont" (selectionChange)="updateCanvas()">
              <mat-option *ngFor="let font of availableFonts" [value]="font">
                <div [style.font-family]="font"> {{font}}</div>
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>
  </div>

</div>
