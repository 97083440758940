<ng-container [formGroup]="form">
  <div>
    <mat-form-field  class="w-100 mat-display-1" style="margin-bottom: 0">
      <input matInput autocomplete="off" placeholder="Name der Website oder Konfiguration" formControlName="name"
             required>
    </mat-form-field>
  </div>
  <ng-container formArrayName="formats">
    <mat-card *ngFor="let format of formats.controls; let i = index" [ngStyle]="{ 'margin-bottom.%': 4 }"
              [formGroupName]="i">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon color="primary">crop</mat-icon>
        </div>
        <mat-card-title>
          [kunde]_[artikel]_{{ format.value.kategorie?.partikel }}[{{format.value.kategorie?.sort | slice:0:3}}...]_{{format.value.name}}.jpg

        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <div [ngStyle]="{ 'margin-bottom.%': 4 }"
                >
            <mat-form-field >
              <input matInput placeholder="Suffix" formControlName="name" width="100%" (blur)="onTouched()">

              <mat-error *ngIf="format.controls['name'].errors && format.controls['name'].errors.pattern">
                Der Suffix darf ausschließlich aus kleinen Buchstaben, Zahlen und Unterstrich bestehen.
                Zahlen enthalten
              </mat-error>
              <mat-error *ngIf="format.controls['name'].errors && format.controls['name'].errors.required">
                Der Suffix ist ein Pflichtfeld
              </mat-error>
              <mat-hint>Suffix des endgültigen Namens</mat-hint>
            </mat-form-field>
            <mat-form-field  >
              <mat-select formControlName="kategorie" [compareWith]="compareWith"  (blur)="onTouched()">
                <mat-option>Keine</mat-option>
                <mat-option *ngFor="let kat of this.form.value.kategorien" [value]="kat">
                  {{kat.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div [ngStyle]="{ 'margin-bottom.%': 4 }"
               >
            <mat-form-field  class="" style="width: 6.5em">
              <input type="number" matInput placeholder="Breite" formControlName="breite"  (blur)="onTouched()">
              <mat-hint>Breite in Pixel</mat-hint>
            </mat-form-field>
            <mat-form-field  class="" style="width: 6.5em">
              <input type="number" matInput placeholder="Höhe" formControlName="hoehe"  (blur)="onTouched()">
              <mat-hint>Höhe in Pixel</mat-hint>
            </mat-form-field>
            <mat-form-field  class="" style="width: 6.5em">
              <input type="number" matInput placeholder="max. Größe" formControlName="max"  (blur)="onTouched()">
              <mat-hint>Max. Größe in Kb</mat-hint>
            </mat-form-field>
          </div>
        </div>

      </mat-card-content>
      <mat-card-actions> <button
        mat-icon-button=""
        color="accent"
        matTooltip="Entferne Format"
        (click)="formats.removeAt(i);">
        <mat-icon>delete</mat-icon></button> </mat-card-actions>
    </mat-card>

    <div>
      <div></div>
      <button
        mat-fab
        color="primary"
        matTooltip="Füge weiteres Format hinzu"
        (click)="formats.push(createFormatForm({name: '', breite: 100, hoehe: 100, max: 200}))">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-container formArrayName="kategorien">

    <div>
      <h1>Kategorien</h1>
      <mat-accordion class="w-100">

        <ng-container *ngFor="let kategorienForm of kategorien.controls; let i = index"  [formGroupName]="i">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{ kategorienForm.value.name }}</mat-panel-title>
            </mat-expansion-panel-header>


            <mat-form-field  class="w-100">
              <input matInput placeholder="Name" formControlName="name"  (blur)="onTouched()">
              <mat-hint>Name der Kategorie</mat-hint>
            </mat-form-field>
            <mat-form-field  class="w-100">
              <input matInput placeholder="ID im Dateinamen" formControlName="partikel" (blur)="onTouched()"
              >
              <mat-hint>ID der Kategorie im Dateinamen</mat-hint>
            </mat-form-field>
            <mat-form-field  class="w-100">
              <input matInput placeholder="Aufzählungszeichen" formControlName="sort" (blur)="onTouched()"
              >
              <mat-hint>Komma-separierte Liste der Aufzählungszeichen</mat-hint>
            </mat-form-field>

          </mat-expansion-panel>
        </ng-container>

      </mat-accordion>
      <div >
        <div></div>
        <button
          mat-fab
          color="primary"
          matTooltip="Füge weitere Kategorie hinzu"
          (click)="kategorien.push(createKategorieForm({name: 'Neue Kategorie', sort: '1,2,3', partikel: 'x', id: randomStr() }))">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

  </ng-container>
</ng-container>

