<div style="padding-left: 20px; padding-right: 20px; display: flex; flex-direction: column; "
     (mouseup)="mouseUp()">
  <mat-tab-group #tab (selectedTabChange)="tabSelected($event.index)" dynamicHeight style="margin-bottom: 16px">
    <mat-tab label="Kein Overlay" (select)="this.selectedImage = null; paintBG()"></mat-tab>
    <mat-tab label="Verbrauchskennzeichnung">
      <ng-template matTabContent>
        <h2>Overlay Erzeugen</h2>
        <app-verbrauchs-text-generator
          (image)="this.verbrauchImage = this.selectedImage = $event; this.scaleAndPosition(90, 5, 4);"
          [availableFonts]="availableFonts"></app-verbrauchs-text-generator>
        <h2 style="margin-top: 0">Overlay Positionieren</h2>

        <div style="display: flex; flex-wrap: wrap; gap: 8px; justify-content: center;"
             [style.flex-direction]="orientation">
          <div style="padding-bottom: 8px">
            <button class="narrow" mat-flat-button color="primary" (click)="scaleAndPosition(100, 0, 0)">Unten&#8203;/&#8203;volle
              Breite
            </button>
          </div>
          <div style="padding-bottom: 8px">
            <button class="narrow" mat-flat-button color="primary" (click)="scaleAndPosition(90, 5, 3)">Unterer
              Bereich&#8203;/&#8203;90% Breite
            </button>
          </div>
          <div style="padding-bottom: 8px">
            <button class="narrow" mat-flat-button color="primary" (click)="scaleAndPosition(80, 10, 3)">Unterer
              Bereich&#8203;/&#8203;80% Breite
            </button>
          </div>
          <div style="padding-bottom: 8px">
            <button class="narrow" mat-flat-button color="primary"
                    (click)="scaleAndPosition(null, (this.ctx.canvas.width - this.selectedImage.width * this.scale) / (0.02 * this.ctx.canvas.width), null)">
              Mittig ausrichten
            </button>
          </div>


          <mat-form-field class="narrow">
            <mat-label>Abstand Links</mat-label>
            <input matInput type="number" min="0" step="1" [(ngModel)]="dx" (change)="paintBG()"/>
          </mat-form-field>
          <mat-form-field class="narrow">
            <mat-label>Abstand Oben</mat-label>
            <input matInput type="number" min="0" step="1" [(ngModel)]="dy" (change)="paintBG()"/>
          </mat-form-field>

          <mat-form-field class="narrow">
            <mat-label>Größe Font px</mat-label>
            <input matInput type="number" min="0" step="0.25" [(ngModel)]="fontSize" (change)="paintBG()"/>
          </mat-form-field>
          <mat-form-field class="narrow">
            <mat-label>Größe %</mat-label>
            <input matInput type="number" min="10" max="100" step="1" [(ngModel)]="widthInPercent"
                   (change)="paintBG()"/>
          </mat-form-field>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Logo Overlay">
      <h2>Overlay Hochladen</h2>
      <app-image-uploader
        (image)="this.logoImage = this.selectedImage = $event; this.scaleAndPosition(10, 5, 4);"></app-image-uploader>
      <h2>Overlay Positionieren</h2>
      <div style="display: flex;justify-content: center; flex-direction: column; flex-wrap: wrap; gap: 8px"
           [style.flex-direction]="orientation">
        <div style="padding-bottom: 8px">
          <button class="narrow" mat-flat-button color="primary" (click)="dx = 10; dy = 10">Oben Links</button>
        </div>
        <div style="padding-bottom: 8px">
          <button class="narrow" mat-flat-button color="primary" (click)="d100x = 10; dy = 10">Oben Rechts</button>
        </div>
        <div style="padding-bottom: 8px">
          <button class="narrow" mat-flat-button color="primary" (click)="dx = 10; d100y = 10">Unten Links</button>
        </div>
        <div style="padding-bottom: 8px">
          <button class="narrow" mat-flat-button color="primary" (click)="d100x = 10; d100y = 10">
            Unten Rechts
          </button>
        </div>

        <mat-form-field class="narrow">
          <mat-label>Abstand Links</mat-label>
          <input matInput type="number" min="0" step="1"
                 [max]="selectedImage?ctx.canvas.width - selectedImage.width * scale:0" [(ngModel)]="dx"
                 (change)="paintBG()"/>
        </mat-form-field>
        <mat-form-field class="narrow">
          <mat-label>Abstand Rechts</mat-label>
          <input matInput type="number" min="0" step="1"
                 [max]="selectedImage?ctx.canvas.width - selectedImage.width * scale:0" [(ngModel)]="d100x"
                 (change)="paintBG()"/>
        </mat-form-field>

        <mat-form-field class="narrow">
          <mat-label>Abstand Oben</mat-label>
          <input matInput type="number" min="0" step="1" [(ngModel)]="dy" (change)="paintBG()"/>
        </mat-form-field>
        <mat-form-field class="narrow">
          <mat-label>Breite in px</mat-label>
          <input matInput type="number" min="1" [max]="this.canvas.width" step="1" [(ngModel)]="widthInPixel"
                 (change)="paintBG()"/>
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div style="margin: auto auto ; display: flex; flex-direction: row; justify-content: center ">


    <canvas #canvas (mousemove)="handleMouseMove($event)" (mousedown)="mouseDown()"></canvas>
  </div>
</div>
