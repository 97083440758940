import {Component, forwardRef, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {Format, Kategorie, Website} from '../../konfiguration.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-website-editor',
  templateUrl: './website-editor.component.html',
  styleUrls: ['./website-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: WebsiteEditorComponent,
      multi: true
    }
  ]
})
export class WebsiteEditorComponent implements ControlValueAccessor, OnInit, OnDestroy {

  touched = false;
  onChangeSubs: Subscription[] = [];

  form = this.fb.group({
    name: [''],
    kategorien: this.fb.array([]),
    formats: this.fb.array([])

  });

  randomStr() {
    return Math.random().toString(36).substr(2, 5) + Math.random().toString(36).substr(2, 5);
  }

  createKategorieForm(kategorie: Kategorie) {
    return this.fb.group({
        name: new FormControl(kategorie.name, [Validators.required]),
        partikel: new FormControl(kategorie.partikel, [Validators.required]),
        sort: new FormControl(kategorie.sort, [Validators.required]),
        id: new FormControl(kategorie.id, [Validators.required]),
      }
    );
  }

  createFormatForm(format: Format) {
    return this.fb.group({
        name: new FormControl(format.name, [Validators.required, Validators.pattern('[a-z0-9_]*')]),
        kategorie: new FormControl(format.kategorie, []),
        breite: new FormControl(format.breite, [Validators.required]),
        hoehe: new FormControl(format.hoehe, [Validators.required]),
        max: new FormControl(format.max, [Validators.required]),
      }
    );
  }


  constructor(private fb: FormBuilder) {
    this.form.valueChanges.subscribe(v => {
      this.formats.controls.forEach((control: FormGroup) => {
        const kategorie = this.kategorien.controls.find(k => k.value?.id === control.value.kategorie?.id);
        if (kategorie !== null && kategorie !== undefined) {
          control.controls['kategorie'].setValue(kategorie.value , {emitEvent: false});
        }
      });
      this.onChange(this.form.value);
    });
  }

  get kategorien() {
    return this.form.controls.kategorien as FormArray;
  }

  get formats() {
    return this.form.controls.formats as FormArray;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    for (const sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  onChange: any = (website) => {
  }

  onTouched: any = () => {
  }

  registerOnChange(onChange: any) {
    const sub = this.form.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  writeValue(value: Website) {
    console.log('aaa');
    if (value) {

      value.kategorien.forEach(kategorie => (this.form.controls['kategorien'] as FormArray).push(this.createKategorieForm(kategorie), {emitEvent: false}));
      value.formats.forEach(format => (this.form.controls['formats'] as FormArray).push(this.createFormatForm(format), {emitEvent: false}));
      this.form.controls['name'].setValue(value.name, {emitEvent: false});

    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    }
    else {
      this.form.enable();
    }
  }

  compareWith(a: Kategorie, b: Kategorie) {

    if (a && b) {
      return a.id === b.id;
    }
    return !(a || b);
  }


}
