<h1>Konfigurationseditor</h1>
<div class="row" >
  <span>Laden sie die Konfiguration hier herunter:
    <a
      [download]="filename"
      [ngStyle]="{'margin-bottom.%': 4 }"
      [href]="dataurl">
      {{filename}}
    </a>
     <mat-icon>save_alt</mat-icon>
  </span>
</div>


<mat-form-field  style="width: 100%">
  <mat-label>Selected Fonts</mat-label>
  <mat-select matInput multiple [(ngModel)]="konfig.selectedFonts">
    <mat-option *ngFor="let font of (fonts | async)?.items" [value]="font['family']">{{font['family']}}</mat-option>
  </mat-select>
</mat-form-field>


<mat-accordion class="headers-align" multi>

  <mat-expansion-panel *ngFor="let w of konfig?.websites; let wi = index; trackBy: trackByIndex">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ w.name }}
      </mat-panel-title>
      <mat-panel-description>


      </mat-panel-description>

    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-website-editor [ngModel]="konfig.websites[wi]" (ngModelChange)="konfig.websites[wi] = $event; changes_done()" [name]="wi"></app-website-editor>
    </ng-template>
    <mat-action-row>
      <button
        mat-icon-button color="accent"
        matTooltip="Entferne Webseite"
        (click)="konfig.websites.splice(wi, 1); $event.preventDefault()">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

<div class="row"  [ngStyle]="{ 'margin-top.%' : 4 }">
  <button
    (click)="
    konfig.websites.push(
    {name: 'Neue Webseite', formats: [{name: '', breite: 100, hoehe: 100, max: 200}], kategorien: konfigService.STDKATS });"
    mat-raised-button
    [ngStyle]="{'margin-bottom.%': 4 }">
    Website hinzufügen
    <mat-icon>add</mat-icon>
  </button>

</div>
