/*
 * Hermite resize - fast image resize/resample using Hermite filter.
 * https://github.com/viliusle/Hermite-resize
 */
import Pica from 'pica';
import {exitCodeFromResult} from '@angular/compiler-cli';



export function resizeCanvas(canvas: HTMLCanvasElement, width: number, height: number) {
  console.log('resize in ' + width.toString(10) + '/' + height.toString(10));

  width = Math.round(width);
  height = Math.round(height);


  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = width;
  tmpCanvas.height = height;

  const tmpContext = tmpCanvas.getContext('2d');
  const picaResizer = new Pica();

  return picaResizer.resize(canvas, tmpCanvas).then(result => {
      console.log('resize done');
      return result;
    });

}

